<template>
    <div
        class="sidebar__search"
        @focusout="onBlur()"
        @focusin="onFocus()"
    >
        <div
            class="sidebar__result-container"
            :class="{
                'sidebar__result-container--open': search.length >= 3 && isFocused
            }"
        >
            <template v-if="!isLoading">
                <div
                    v-if="searchItems.data && searchItems.data.length"
                    class="sidebar__result"
                >
                    <div class="sidebar__result-block">
                        <search-item
                            v-for="item in searchItems.data"
                            :key="item.id"
                            :item="item"
                            class="sidebar__result-item"
                        />
                    </div>

                    <router-link
                        v-show="searchItems.count > 4"
                        :to="`/search?search=${search}`"
                        class="sidebar__result-link"
                        >Все результаты</router-link
                    >
                </div>

                <div v-else class="sidebar__result-empty">
                    <div class="sidebar__result-empty-emoji">
                        <emoji emoji="eyes" set="apple" :size="28" />
                    </div>
                    <p class="sidebar__result-empty-text">Ничего не найдено</p>
                </div>
            </template>

            <loading-spinner class="sidebar__spinner" v-else />
        </div>

        <div class="sidebar__input-container">
            <icon width="12" height="12" class="sidebar__input-container-svg">
                <path
                    d="M8.30359 7.1208C9.74406 5.08673 9.26229 2.27014 7.22822 0.830012C5.19415 -0.610115 2.37756 -0.128696 0.937434 1.90572C-0.502693 3.93979 -0.0212742 6.75604 2.01314 8.19616C3.46497 9.22404 5.38445 9.30319 6.91576 8.39885L10.243 11.7061C10.6023 12.0843 11.2 12.0995 11.5782 11.7402C11.9564 11.3813 11.9715 10.7836 11.6126 10.4054C11.6013 10.3933 11.5902 10.3823 11.5782 10.371L8.30359 7.1208ZM4.61776 7.42913C3.01039 7.42947 1.70722 6.12734 1.70619 4.51997C1.70585 2.9126 3.00798 1.60943 4.61569 1.60875C6.22099 1.60806 7.52347 2.90813 7.52657 4.51343C7.52932 6.12114 6.22788 7.42638 4.61982 7.42913C4.61913 7.42913 4.61879 7.42913 4.61776 7.42913Z"
                    fill="#989CAE"
                />
            </icon>

            <input
                @keyup.enter="redirect"
                autocomplete="off"
                class="sidebar__form-input"
                id="sidebar-input"
                type="text"
                v-model.trim="search"
                placeholder="Поиск по порталу"
            />
        </div>
    </div>
</template>

<script>
    import { Emoji } from 'emoji-mart-vue'
    import SearchItem from '../search/SearchItem'
    import session from '@/api/session'
    import LoadingSpinner from '@/components/LoadingSpinner'
    import Icon from '@/components/icon/Icon.vue'
    import debounce from '@/helpers/debounce'

    export default {
        data() {
            return {
                search: this.$route.query.search || '',
                isFocused: false,
                searchItems: {
                    data: []
                },
                isLoading: false,
                timer: 0
            }
        },
        components: {
            Emoji,
            SearchItem,
            LoadingSpinner,
            Icon
        },
        watch: {
            search(value) {
                if (value.length >= 3) this.debouncedSearch()
            },
            deep: true
        },
        created() {
            this.debouncedSearch = debounce(this.getSearch, 500)
        },
        methods: {
            redirect() {
                this.$router.push(`/search/?search=${this.search}`)
            },
            onBlur() {
                this.isFocused = false
            },
            onFocus() {
                this.isFocused = true
            },
            async getSearch() {
                this.isLoading = true
                await session
                    .get(`/api/v1/search/menu/?search=${this.search}`)
                    .then(response => {
                        this.searchItems = {
                            ...response.data,
                            data: response.data.data.filter(
                                (item, index) => index < 5
                            )
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
                this.isLoading = false
            }
        },
        mounted() {
            if (this.search.length) {
                this.getSearch()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sidebar {
        &__spinner {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: scale(0.5);
        }

        &__result {
            width: 100%;
            display: flex;
            flex-direction: column;
            min-width: 0;
            &-container {
                position: absolute;
                left: 50%;
                display: flex;
                padding: 18px;
                min-height: 300px;
                width: 95%;
                background: #fff;
                border-radius: 4px 4px 0 0;
                transform: translate(-50%, 0);
                transition: transform 200ms ease;
                z-index: 101;
                &--open {
                    transform: translate(-50%, -100%);
                }
            }
            &-empty {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex: 1;
                &-emoji {
                    margin-bottom: 8px;
                }
                &-text {
                    margin: 0;
                    font-size: 13px;
                    line-height: 16px;
                    color: #989cae;
                }
            }
            &-block {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                & > .sidebar__result-item + .sidebar__result-item {
                    margin-top: 10px;
                }
            }
            &-link {
                margin: 15px 0 0 28px;
                font-size: 13px;
                line-height: 16px;
                color: #2173d3;
                &:hover {
                    color: #5690d3;
                }
            }
        }
        &__input-container {
            position: relative;
            display: flex;
            align-items: center;
            background: #252831;
            border-top: 1px solid #494d5a;
            transition: border-color 200ms ease;
            z-index: 102;
            &:focus-within {
                border-color: #97a5ba;
                outline: 0;
                box-shadow: none;
            }
            &-svg {
                margin: 0 13px;
            }
        }
        &__form {
            &-block {
                display: flex;
                margin-bottom: 32px;
            }
            &-input {
                flex: 1;
                height: 52px;
                border: none;
                background: #252831;
                color: #fff;
                &:focus {
                    outline: 0;
                    box-shadow: none;
                }
            }
        }
    }
</style>
