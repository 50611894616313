import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import UUID from 'vue-uuid'
import VTooltip from 'v-tooltip'
import vmodal from 'vue-js-modal'
import vSelect2 from 'vue-select'
import Fragment from 'vue-fragment'
import ReadMore from 'vue-read-more'
import VueScrollTo from 'vue-scrollto'
import VueSweetalert2 from './libs/vue-sweetalert2'
import VueProgressBar from 'vue-progressbar'
import TextareaAutosize from 'vue-textarea-autosize'
import VueCookies from 'vue-cookies'
import KeyPress from 'vue-keypress'
import VueShepherd from 'vue-shepherd'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueDragscroll from 'vue-dragscroll'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueNumberInput from '@chenfengyuan/vue-number-input'
import VuePhoneNumberInput from 'vue-phone-number-input'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import VueClipboard from 'vue-clipboard2'
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import VueCompositionApi from '@vue/composition-api'
import VueYoutube from 'vue-youtube'
import {
  Dropdown,
  DropdownMenu,
  DropdownItem
} from 'element-ui'
/* Import Styles */
import './libs/sweetalert2/dist/sweetalert2.min.css'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import 'swiper/css/swiper.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

/* Plugins */
import './plugins/axios'
import './plugins/csrftoken'
import './plugins/validation'
import 'vue-select/dist/vue-select.css'
import ToggleButton from 'vue-js-toggle-button'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import VueMeta from 'vue-meta'

/* Directives */
import clickOutside from 'assets/js/directives/click-outside'

/* Use packages */
const moment = require('moment')
require('moment/locale/ru')
const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)
Vue.use(UUID)
Vue.use(ReadMore)
Vue.use(TextareaAutosize)
Vue.use(VueSweetalert2)
Vue.use(VTooltip, {
    defaultTrigger: (window.innerWidth > 768) ? 'hover focus click' : 'click'
})
Vue.use(VueInputMask)
Vue.use(require('vue-moment'), {
    moment
})
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(vmodal, {
    dynamic: true,
    injectModalsContainer: true
})
Vue.use(Fragment.Plugin)
Vue.use(VueScrollTo)
Vue.component('vSelect2', vSelect2)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
Vue.use(ToggleButton)
Vue.use(VueProgressBar, {
  color: '#fdd174',
  failedColor: '#9d4955',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})
Vue.use(VueCookies)
Vue.use(KeyPress)
Vue.use(VueAwesomeSwiper)
Vue.use(VueDragscroll)
Vue.use(VueShepherd)
Vue.use(VueYandexMetrika, {
    id: 48067895,
    router: router,
    env: 'production',
    triggerEvent: true,
    debug: false
})
Vue.use(VueNumberInput) // Input для полей с типом number
Vue.component('VueDatePicker', VueDatePicker)
Vue.component('vue-phone-number-input', VuePhoneNumberInput) // Ввод мобильного телефона с выбором страны
Vue.use(PerfectScrollbar)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(VueYoutube)
Vue.use(VueCompositionApi)
Vue.component('el-dropdown', Dropdown)
Vue.component('el-dropdown-menu', DropdownMenu)
Vue.component('el-dropdown-item', DropdownItem)
/* Other  */

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
export const eventBus = new Vue()
