<template>
    <div
        v-if="isShowContent"
        id="page-container"
        class="wrapper side-scroll page-header-modern side-trans-enabled"
        :class="{'sidebar-o': isShowSidebar, 'sidebar-h': !isShowSidebar}"
        :style="`--aside-width: ${asideWidth}px`"
        @mousemove="onAsideChangeWidth"
        @mouseup="onAsideChangeEnd"
    >
        <aside
            v-show="isShowSidebar"
            class="aside"
            id="hide-print-css"
        >
            <sidebar
                v-if="$vssWidth > 1000"
                class="sidebar--desktop"
            ></sidebar>

            <sidebar-mobile v-else class="sidebar--mobile"></sidebar-mobile>

            <div
                :class="['aside__strip', { 'visible': asideDragging }]"
                @mousedown="onAsideChangeStart"
            ></div>
        </aside>

        <main class="main">
            <transition name="fade" mode="out-in">
                <router-view :key="$route.fullPath"/>
            </transition>
        </main>

        <!--<footer id="page-footer" class="opacity-0">
          <div class="content py-20 clearfix">
            <div class="float-left">
              <span class="font-w600" target="_blank">WikiWorks</span> &copy; <span class="js-year-copy"></span>
            </div>
          </div>
        </footer>-->

        <div class="info-cookie" v-if="!termsOfUse">
            <p class="info-cookie__text">
                WikiWorks использует cookie-файлы. С их помощью мы улучшаем работу нашего сайта и сервиса, а также повышаем
                качество Вашего пользовательского опыта.
                Пользуясь нашим сайтом и сервисом, Вы даете свое согласие на использование cookie-файлов.
            </p>

            <button
                class="btn info-cookie__btn"
                type="button"
                @click="acceptTermsOfUse"
            >
                Понятно
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import Sidebar from './components/sidebar/Sidebar'
    import SidebarMobile from './components/sidebar/mobile/SidebarMobile'
    import Login from './views/auth/Login'
    import session from './api/session'
    import VueScreenSize from 'vue-screen-size'
    import '@/helpers/shepherdCancel.js'

    export default {
        name: 'App',
        components: {
            Sidebar,
            SidebarMobile,
            Login
        },

        mixins: [VueScreenSize.VueScreenSizeMixin],

        data() {
            return {
                termsOfUse: true,
                asideWidth: 300,
                asideDragging: false
            }
        },

        computed: {
            isError() {
                return !!this.$route.meta.isError
            },

            isShowContent() {
                return this.$route.meta.requiresAuth !== undefined && this.isRouteShown
            },

            isShowSidebar() {
                return !this.isError && this.isAuthenticated && this.$route.meta.title !== 'Мастер настройки портала' && this.isSidebarOpened
            },

            ...mapGetters('default_data', [
                'current_user', 'domain', 'onWizard', 'isRouteShown', 'isAdmin'
            ]),

            ...mapGetters('auth', [
                'isAuthenticated'
            ]),

            ...mapState('sidebar', [
                'isSidebarOpened'
            ]),

            ...mapGetters('sidebar', [
                'currentDomain'
            ])
        },

        async created() {
            await this.initializeApp()

            this.$store.dispatch('default_data/setRouteShown', true)
            this.asideWidth = localStorage.getItem('aside-width') || 300
        },

        methods: {
            logout() {
                this.$store.dispatch('auth/logout').then(() => this.$router.push('/login'))
            },

            acceptTermsOfUse() {
                this.$cookies.set('terms_of_use', true)
                this.termsOfUse = true
            },

            async initializeApp() {
                try {
                    await session.get('/api/v1/main')
                    await this.$store.dispatch('auth/initialize')

                    if (this.isAuthenticated && !this.isError) {
                        await this.$store.dispatch('default_data/initialize')
                        await this.$store.dispatch('navigation/initialize')
                        await this.$store.dispatch('settings/setPortalInfo')
                        await this.$store.dispatch('settings/setPaymentDetail')

                        if (this.isAdmin) {
                            await this.$store.dispatch('users/initializeUserEditor')
                        }
                    }

                    this.termsOfUse = this.$cookies.get('terms_of_use')

                    if (this.onWizard) {
                        await this.$router.push('/start')
                    }
                } catch (e) {
                    console.log('e', e)
                    console.log('e.response', e.response)
                    console.log('e.response.status', e.response.status)
                    if (e.response.status === 403) {
                        await this.$router.push({ name: 'error403' })
                    }
                }
            },

            onAsideChangeStart(event) {
                this.asideDragging = true
                document.body.classList.add('cursor-col-resize')
            },

            onAsideChangeEnd() {
                this.asideDragging = false
                document.body.classList.remove('cursor-col-resize')
            },

            onAsideChangeWidth(event) {
                if (this.asideDragging === true) {
                    if (event.clientX <= 780 && event.clientX >= 220) {
                        this.asideWidth = event.clientX
                        localStorage.setItem('aside-width', this.asideWidth)
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    .wrapper {
        display: flex;
        @media screen and (max-width: 1000px) {
            flex-direction: column;
        }
    }

    .aside {
        top: 0px;
        position: sticky;
        display: flex;
        overflow: hidden;
        &__strip {
            top: 0px;
            right: 0px;
            bottom: 0px;
            position: absolute;
            user-select: none;
            cursor: col-resize;
            opacity: 0;
            width: 3px;
            background-color: $gold-main;
            &:hover {
                opacity: 1;
            }

            &.visible {
                opacity: 1;
            }
        }

        @media screen and (min-width: 1001px) {
            padding-right: 3px;
            height: 100vh;
            width: var(--aside-width);
            min-width: var(--aside-width);
            background-color: var(--sidebar-bg);
        }

        @media screen and (max-width: 1000px) {
            z-index: 999;
            height: 60px;
            padding: 20px;
            background-color: var(--sidebar-mobile-bg);
            &__strip {
                display: none;
            }
        }
    }

    .main {
        flex-grow: 1;
        min-width: 0px;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .sidebar-h {
        padding-left: 0 !important;
    }

    .vd-picker__input-clear{
        position: relative;
        top: 5px;
        left: 10px;
    }

    .form-control .vd-picker__input input{
        min-width: 92px;
    }

    @media screen and (max-height: 760px){
        .vd-menu__content.vd-menu__content--active.vd-menu__content--fixed{
            position: absolute;
            top: calc(50% - 187px) !important;
        }
    }
</style>
