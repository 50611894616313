import session from '../api/session';
import {
    WRAPPER_MATERIALS
} from './types';

const state = {
    favourites: {
        count: 0,
        list: []
    },
    old: {
        count: 0,
        list: []
    },
    learning: {
        count: 0,
        list: []
    }
};


const mutations = {
    [WRAPPER_MATERIALS](state, data) {
        state.favourites = data.favourites;
        state.learning = data.learning;
        state.old = data.old;
        state.learning = data.learning;
    },
};

const actions = {
    initialize({commit}) {
        return session.get('/api/v1/dashboard-widget/materials/')
            .then(({data}) => commit(WRAPPER_MATERIALS, data))
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters: {},
    actions
};
