export const ACTIVATION_BEGIN = 'ACTIVATION_BEGIN';
export const ACTIVATION_CLEAR = 'ACTIVATION_CLEAR';
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';

export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_CLEAR = 'LOGIN_CLEAR';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT = 'LOGOUT';

export const PASSWORD_EMAIL_BEGIN = 'PASSWORD_EMAIL_BEGIN';
export const PASSWORD_EMAIL_CLEAR = 'PASSWORD_EMAIL_CLEAR';
export const PASSWORD_EMAIL_FAILURE = 'PASSWORD_EMAIL_FAILURE';
export const PASSWORD_EMAIL_SUCCESS = 'PASSWORD_EMAIL_SUCCESS';
export const PASSWORD_RESET_BEGIN = 'PASSWORD_RESET_BEGIN';
export const PASSWORD_RESET_CLEAR = 'PASSWORD_RESET_CLEAR';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';

export const SET_TOKEN = 'SET_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const SET_WIZARD = 'SET_WIZARD';
export const SET_PHONE_MASKS = 'SET_PHONE_MASKS';

export const DEFAULT_DATA = 'DEFAULT_DATA';
export const KNOWLEDGE_BASE = 'KNOWLEDGE_BASE';
export const WRAPPER_MATERIALS = 'WRAPPER_MATERIALS';
export const NAVIGATION = 'NAVIGATION';
export const SETTINGS_FORM = 'SETTINGS_FORM';
export const SET_DOMAINS_LIST = 'SET_DOMAINS_LIST';
export const SET_PICKER = 'SET_PICKER';
export const SET_SIDEBAR_OPENED = 'SET_SIDEBAR_OPENED';
export const SET_PORTAL_INFO = 'SET_PORTAL_INFO';
export const SET_RATES = 'SET_RATES';
export const SET_PAYMENT_DETAIL = 'SET_PAYMENT_DETAIL';
export const SET_ALL_STAFF = 'SET_ALL_STAFF';
export const SET_RECOMMENDATION_STAFF = 'SET_RECOMMENDATION_STAFF';
export const SET_RECOMMENDATION_ADMIN = 'SET_RECOMMENDATION_ADMIN';
export const SET_STAFF_MONTH = 'SET_STAFF_MONTH';
export const SET_ROUTE_SHOWN = 'SET_ROUTE_SHOWN';
export const MATERIALS_LIST = 'MATERIALS_LIST';
export const EDIT_MATERIAL = 'EDIT_MATERIAL';
export const REMOVE_MATERIAL = 'REMOVE_MATERIAL';
export const ADD_MATERIAL = 'ADD_MATERIAL';
export const SET_PREV_MATERIAL_ID = 'SET_PREV_MATERIAL_ID';

export const DOCUMENTS = 'DOCUMENTS';
export const ADD_DOCUMENTS = 'ADD_DOCUMENT';

export const USERS_EDITOR = 'USERS_EDITOR';
export const ADD_USER_EDITOR = 'ADD_USER_EDITOR';
export const REMOVE_USER_EDITOR = 'REMOVE_USER_EDITOR';
export const CHANGE_EXCLUDE_USER_EDITOR = 'CHANGE_EXCLUDE_USER_EDITOR';
export const EXCLUDES_USER_EDITOR = 'EXCLUDES_USER_EDITOR';
