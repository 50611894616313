<template>
    <section
        class="sidebar-mobile hide-print-css"
        v-click-outside="hide"
        :style="`--sidebar-mobile-left: ${sidebarLeft};`"
        @touchmove="onSidebarChangeWidth"
        @touchcancel="sidebarDragging = false"
    >
        <div class="sidebar-mobile__panel">
            <panel/>
        </div>

        <div class="sidebar-mobile__expand">
            <i
                class="sidebar-mobile__burger"
                :class="{'sidebar-mobile__burger--opened':isBurgerOpened}"
                @click="switchState"
            />

            <div v-show="isBurgerOpened" class="sidebar-mobile__menu">
                <sidebar
                    v-show="isBurgerOpened"
                    :opened="isBurgerOpened"
                    @onSwitch="hide"
                    class="sidebar-mobile__nav"
                />

                <div
                    class="sidebar-mobile__strip"
                    @touchstart="sidebarDragging = true"
                ></div>
            </div>
        </div>
    </section>
</template>

<script>
    import { eventBus } from '@/main'

    export default {
        name: 'SidebarMobile',
        components: {
            Sidebar: () => import('../Sidebar'),
            Panel: () => import('../header/Panel')
        },

        data() {
            return {
                isBurgerOpened: false,
                sidebarLeft: 'calc(100% - 300px)',
                sidebarDragging: false
            }
        },

        created() {
            this.sidebarLeft = localStorage.getItem('sidebar-mobile-left') || 'calc(100% - 300px)'
        },

        watch: {
            $route(to, from) {
                eventBus.$emit('nav-close')
            }
        },

        methods: {
            switchState() {
                this.isBurgerOpened = !this.isBurgerOpened
            },

            hide() {
                this.isBurgerOpened = false
            },

            onStart(event) {
                this.sidebarDragging = true
            },

            onSidebarChangeWidth(event) {
                const touch = event.touches[0]

                if (this.sidebarDragging === true) {
                    if (
                        touch.clientX <= 220 &&
                        touch.clientX >= 0
                    ) {
                        this.sidebarLeft = `${touch.clientX}px`
                        localStorage.setItem('sidebar-mobile-left', this.sidebarLeft)
                    }
                }
            }
        },

        mounted() {
            eventBus.$on('nav-close', () => {
                this.isBurgerOpened = false;
            })
        }
    }
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .sidebar-mobile {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        &__panel {
            width: 100%;
            min-width: 0px;
        }

        &__burger {
            cursor: pointer;
            margin-left: 20px;
            @include icon($sidebar-burger, 18px);
            &--opened {
                background-image: $sidebar-cross;
            }
        }

        &__menu {
            top: 60px;
            right: 0px;
            bottom: 0px;
            left: var(--sidebar-mobile-left);
            position: fixed;

            display: flex;
            background-color: var(--sidebar-bg);

            @media screen and (max-width: 380px) {
                left: 0px;
                width: 100%;
            }
        }

        &__strip {
            top: 0px;
            left: 0px;
            bottom: 0px;
            position: absolute;
            user-select: none;
            cursor: col-resize;
            width: 10px;
        }

        &__nav {
            width: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    }
</style>
