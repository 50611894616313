import session from '../api/session';
import {
    DEFAULT_DATA, SET_TOKEN, SET_ROUTE_SHOWN, SET_SIDEBAR_OPENED, SET_PICKER
} from './types'

const state = {
    isRouteShown: false,
    onWizard: false,
    domain: {},
    current_user: {},
    time: {
        date: '',
        time_zone: ''
    },
    module: {},
    limits: {
        MIN_CHARS: 4
    },
    MONTHS_NAMES: {
        1: 'Январь',
        2: 'Февраль',
        3: 'Март',
        4: 'Апрель',
        5: 'Май',
        6: 'Июнь',
        7: 'Июль',
        8: 'Август',
        9: 'Сентябрь',
        10: 'Октябрь',
        11: 'Ноябрь',
        12: 'Декабрь',
    },
    newCourseId: null
};

const mutations = {
    [DEFAULT_DATA](state, data) {
        state.onWizard = data['onWizard'];
        state.domain = data['domain'];
        state.module = data['module'];
        state.time = data['time'];
        state.current_user = data['user'];
        state.knowledge_base = data['knowledge_base'];
    },
    [SET_ROUTE_SHOWN](state, data) {
        state.isRouteShown = data;
    },
    setNewCourseId(state, data) {
        state.newCourseId = data
    }
};

const actions = {
    initialize({commit}) {
        return session.get('/api/v1/default-data/')
            .then(({data}) => commit(DEFAULT_DATA, data))
    },
    setRouteShown({commit}, data) {
        commit(SET_ROUTE_SHOWN, data);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters: {
        current_user: state => state.current_user,
        module: state => state.module,
        domain: state => state.domain,
        onWizard: state => state.onWizard,
        isRouteShown: state => state.isRouteShown,
        isAdmin: state => state.current_user.is_admin,
        isEditorCourse: state => state.current_user.is_editor_course,
        isEditorMaterial: state => state.current_user.is_editor_material,
        knowledgeBaseId: state => state.knowledge_base,
        newCourseId: state => state.newCourseId
    },
    actions
};
