<template>
    <div v-show="isSidebarOpened">
        <perfect-scrollbar
            tag="section"
            id="sidebar"
            class="sidebar sidebar--main hide-print-css"
        >
<!--            <div class="sidebar-mobile__close" @click="$emit('onSwitch')">-->
<!--                <i class="sidebar-mobile__close-icon" />-->
<!--            </div>-->
            <div class="sidebar__content">
                <div class="sidebar__header" v-if="!isMobile">
                    <panel />
                </div>
                <div class="sidebar__body">
                    <navigation />
                </div>
            </div>
        </perfect-scrollbar>

        <div class="sidebar--footer">
            <tree-picker />
            <search />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import Search from '@/components/sidebar/search/Search'

    export default {
        name: 'Sidebar',
        components: {
            TreePicker: () => import('@/components/sidebar/tree/TreePicker'),
            Panel: () => import('./header/Panel'),
            Navigation: () => import('./navigation/Navigation'),
            Search
        },

        props: {
            isMobile: {
                type: Boolean,
                default: false
            },
            opened: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isOpened: !this.opened
            }
        },

        created() {
            if (this.isAuthenticated) {
                this.initSidebarStore()
            }
        },

        computed: {
            // Не вздумайте делать отображение через v-if
            ...mapState('sidebar', ['isSidebarOpened']),
            ...mapGetters('auth', ['isAuthenticated'])
        },

        watch: {
            opened(newValue, oldValue) {
                this.isOpened = newValue
            },
            isAuthenticated(newValue, oldValue) {
                if (newValue === true) {
                    this.initSidebarStore()
                }
            }
        },

        methods: {
            initSidebarStore() {
                this.$store.dispatch('sidebar/initialize')
                this.$store.dispatch('knowledge_base/setMaterialsList')
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    .sidebar {
        &--main {
            flex: 1 0 auto;
            overflow-y: auto;
            height: calc(100% - 52px);
        }
    }

    .sidebar {
        padding: $sidebar-padding-top $sidebar-padding-left;
        &__content {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        &__header {
            height: 40px;
            margin-bottom: 30px;
        }

        &__body {
            height: 100%;
        }

        &__footer {
            margin-top: auto;
        }

        .ps__rail-y {
            z-index: 99;
        }
    }
</style>
