import session from '../api/session'
import {
    USERS_EDITOR,
    ADD_USER_EDITOR,
    REMOVE_USER_EDITOR,
    EXCLUDES_USER_EDITOR
} from './types'

const state = {
    usersEditorList: []
}

const mutations = {
    [USERS_EDITOR](state, data) {
        state.usersEditorList = data
    },

    [ADD_USER_EDITOR](state, data) {
        state.usersEditorList.push(data)
    },

    [REMOVE_USER_EDITOR](state, data) {
        state.usersEditorList = state.usersEditorList.filter((user) => {
            if (user.user !== data.user) {
                return user
            }
        })
    },

    [EXCLUDES_USER_EDITOR](state, data) {
        state.usersEditorList = state.usersEditorList.map((user) => {
            if (user.user === data.user) {
                user.excludes = data.excludes
            }

            return user
        })
    }
}

const actions = {
    initializeUserEditor({ commit }) {
        return session
            .get('/api/v1/material_editor/list/')
            .then(({ data }) => commit(USERS_EDITOR, data))
    },

    addUserEditor({ commit }, data) {
        return session
            .post('/api/v1/material_editor/', data)
            .then(() => commit(ADD_USER_EDITOR, data))
    },

    removeUserEditor({ commit }, data) {
        return session
            .delete('/api/v1/material_editor/', { data: data })
            .then(() => commit(REMOVE_USER_EDITOR, data))
    },

    excludesUserEditor({ commit }, data) {
        return session
            .put(`/api/v1/material_editor/${data.user}/`, data)
            .then(() => commit(EXCLUDES_USER_EDITOR, data))
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters: {
        usersEditorList: state => state.usersEditorList
    },
    actions
}
