<template>
    <div class="auth__content hero-static content content-full" v-show="isShown">
        <!-- Avatar -->
        <div class="py-30 px-5 text-center content-header__wrapper">
            <img class="content-header__logo" src="~assets/img/wikiworks-logo.png" height="32" width="194"
                 alt="Wikiworks">
        </div>
        <!-- END Avatar -->

        <!-- Unlock Content -->
        <div class="row justify-content-center px-5">
            <div class="col-sm-8 col-md-6 col-lg-6 d-flex justify-content-center autorization__wrapper">
                <div class="modal__block autorization__content">
                    <h3 class="autorization__title">Авторизация</h3>
                    <div class="autorization__tab-content">
                        <!-- Вкладка входа -->
                        <div class="autorization__tab">
                            <!-- Форма входа -->
                            <ValidationObserver
                                tag="form"
                                ref="observer"
                                @submit.prevent="login(inputs)"
                                class="form"
                                id="id_login_form"
                            >
                                <div class="form__content">
                                    <ValidationProvider
                                        tag="div"
                                        :rules="`required|email`"
                                        class="form__group"
                                        vid="email"
                                        v-slot="{errors}"
                                    >
                                        <input v-model="inputs.email" type="text" name="email" placeholder="E-mail"
                                               class="form__control_auth email" :class="{'form__error':errors.length}">
                                        <span class="v-error animated fadeInDown" v-if="errors.length">
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                    <ValidationProvider
                                        tag="div"
                                        class="form__group flex-column"
                                        :rules="`required`"
                                        vid="password"
                                        v-slot="{errors}"
                                    ><div
                                            @click="isPasswordHidden = !isPasswordHidden"
                                            class="form__display-pass js-display-pass"
                                            :class="{'form__display-pass--closed' : !isPasswordHidden}"
                                        ></div>
                                        <input
                                            v-model="inputs.password"
                                            :type="isPasswordHidden ? 'password' : 'text'"
                                            placeholder="Пароль"
                                            name="password"
                                            class="form__control_auth pass"
                                            id="id_password"
                                        >
                                        <span class="v-error animated fadeInDown" v-if="errors.length">
                                            {{ errors[0] }}
                                        </span>
                                        <ValidationProvider
                                            tag="span"
                                            class="v-error animated fadeInDown"
                                            vid="non_field_errors"
                                            v-slot="{errors}"
                                        >
                                            {{ errors[0] }}
                                        </ValidationProvider>
                                    </ValidationProvider>
                                    <div class="form__group">
                                        <label class="css-control">
                                            <input
                                                type="checkbox"
                                                id="id-not-right"
                                                v-model="isPasswordSave"
                                                class="css-control-input"
                                            >
                                            <span class="css-control-indicator"></span>
                                            <span class="form__save-label">Запомнить меня на этом компьютере</span>
                                        </label>
                                    </div>
                                    <div class="form__group form__group--short">
                                        <button type="submit"
                                                class="autorization__btn btn btn_first btn_first-fill autorization__btn--margin">
                                            Войти
                                        </button>
                                    </div>
                                    <div class="form__group">
                                        <router-link tag="a" to="/password_reset"
                                                     class="autorization__btn btn btn_third btn_third-fill">
                                            Восстановить пароль
                                        </router-link>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Unlock Content -->
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                inputs: {
                    email: '',
                    password: ''
                },
                isPasswordSave: false,
                isPasswordHidden: true,
                isShown: true
            }
        },
        computed: {
            ...mapGetters('auth', [
                'authErrors', 'isAuthenticated', 'onWizard'
            ]),
            ...mapGetters('default_data', [
                'isAdmin'
            ]),
            ...mapGetters('url_login', [
                'getUrlLogin'
            ]),

        },
        methods: {
            async login({ email, password }) {
                console.log('login')
                let isValid = await this.$refs.observer.validate();
                let params = {
                    remember_me: this.isPasswordSave
                }
                if (isValid) {
                    this.$store.dispatch('auth/login', {
                        email,
                        password,
                        params
                    }).then(async (res) => {
                        await this.$store.dispatch('default_data/initialize');
                        await this.$store.dispatch('navigation/initialize');
                        await this.$store.dispatch('settings/setPortalInfo');
                        await this.$store.dispatch('settings/setPaymentDetail');
                        this.isShown = false;

                        if (this.onWizard) {
                            await this.$router.push("/start");
                        } else if (this.getUrlLogin) {
                            await this.$router.push(this.getUrlLogin);
                        } else {
                            await this.$router.push("/");
                        }

                        if (this.isAdmin) {
                            await this.$store.dispatch('users/initializeUserEditor');
                            await this.$metrika.reachGoal('login-admin');
                        }
                    }).catch((err) => {
                        console.log('err', err)
                        this.$refs.observer.setErrors(this.authErrors);
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "auth";

    .form {
        &__save-label {
            user-select: none;
            font-size: 0.875rem;
            margin-left: 10px;
        }
    }
</style>
