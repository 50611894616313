import session from '../api/session'
import { SET_DOMAINS_LIST, SET_PICKER, SET_SIDEBAR_OPENED } from './types'

const state = {
    name: '',
    domainsList: {},
    picker: {
        opened: false,
        id: null,
        content_type: ''
    },
    isSidebarOpened: true
}

const mutations = {
    [SET_DOMAINS_LIST] (state, data) {
        state.domainsList = data
        state.name = data.current.name
    },
    [SET_PICKER] (state, data) {
        state.picker = data
    },
    [SET_SIDEBAR_OPENED] (state, data) {
        state.isSidebarOpened = data
    }
}

const actions = {
    initialize ({ commit }) {
        session
            .get('/api/v1/menu/domain/')
            .then(({ data }) => commit(SET_DOMAINS_LIST, data))
        commit(SET_PICKER, {
            opened: false,
            id: null,
            content_type: ''
        })
    },
    setPicker ({ commit }, data) {
        commit(SET_PICKER, data)
    },
    setSidebarOpened ({ commit }, data) {
        commit(SET_SIDEBAR_OPENED, data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters: {
        domainsList: state => state.domainsList,
        currentDomain: state => state.domainsList.current,
        otherDomains: state => state.domainsList.other
    },
    actions
}
