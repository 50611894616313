import session from '../api/session'
import {
    KNOWLEDGE_BASE,
    MATERIALS_LIST,
    EDIT_MATERIAL,
    REMOVE_MATERIAL,
    ADD_MATERIAL
} from './types'

const state = {
    knowledgeBase: {},
    materialsList: []
}

function search(data, id) {
    let found = data.find(d => d.id === id)
    if (!found) {
        let i = 0
        while (!found && i < data.length) {
            if (data[i].children && data[i].children.length) {
                found = search(data[i].children, id)
            }
            i++
        }
    }
    return found
}

const mutations = {
    [KNOWLEDGE_BASE](state, data) {
        state.knowledgeBase = data
    },
    [MATERIALS_LIST](state, data) {
        state.materialsList = data
    },
    ADD_CHILDREN(state, data) {
        const parent = search(state.materialsList, +data.parent)
        parent.children.push(...data.children)
    },
    [ADD_MATERIAL](state, data) {
        const material = search(state.materialsList, +data.parent)
        if (state.materialsList.length && material) {
            material.have_children = true
            material.children.push(data)
        } else {
            state.materialsList.push(data)
        }
    },
    [EDIT_MATERIAL] (state, data) {
        if (state.materialsList.length) {
            const material = search(state.materialsList, +data.id)
            if (material) {
                material.title = data.title || material.title
                material.required =
                    typeof data.required === 'undefined'
                        ? material.required
                        : data.required
                material.published =
                    typeof data.published === 'undefined'
                        ? material.published
                        : data.published
                material.have_children =
                    typeof data.have_children === 'undefined'
                        ? material.have_children
                        : data.have_children
            }
        }
    },
    [REMOVE_MATERIAL](state, data) {
        if (state.materialsList.length) {
            if (data.parent) {
                const material = search(state.materialsList, +data.parent)
                if (material) {
                    material.children = material.children.filter(
                        item => item.id !== data.id
                    )
                    if (material.children.length === 0) {
                        material.have_children = false
                    }
                } else {
                    state.materialsList = state.materialsList.filter(
                        item => item.id !== data.id
                    )
                }
            }
        }
    }
}

const actions = {
    initialize({ commit }) {
        return session
            .get('/api/v1/rubric/')
            .then(({ data }) => commit(KNOWLEDGE_BASE, data))
    },
    setMaterialsList({ commit }) {
        return session
            .get('/api/v1/knowledge-base/')
            .then(({ data }) => commit(MATERIALS_LIST, data.children))
    },
    addMaterial({ commit }, data) {
        commit(ADD_MATERIAL, data)
    },
    addChildren({ commit }, data) {
        commit('ADD_CHILDREN', data)
    },
    editMaterial({ commit }, data) {
        commit(EDIT_MATERIAL, data)
    },
    removeMaterial({ commit }, data) {
        commit(REMOVE_MATERIAL, data)
    },
    sortMaterialsList({ commit }, value) {
        window.localStorage.setItem('sortMaterial', value)

        return session
            .get(`/api/v1/knowledge-base?ordering=${value}`)
            .then(({ data }) => commit(MATERIALS_LIST, data.children))
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters: {
        rubrics: state => state.knowledgeBase.rubrics,
        materials: state => state.knowledgeBase.list
    },
    actions
}
